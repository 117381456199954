@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js");
@import url("https://fonts.googleapis.com/css?family=Oxygen:300&display=swap");
body {
  font-family: "Oxygen", sans-serif;
  font-size: 18px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
}

.loader {
  display: flex;
  justify-content: center;
  padding: 42vh;
  text-align: center;
}

.responsive {
  width: 100%;
  height: auto;
}
