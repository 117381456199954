.project-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .project-wrapper {
    width: 400px;
  }

  .mobile {
    font-size: 3rem;
  }
}

.no-border {
  border-style: none;
}
p {
  line-height: 2em;
}

.drawer {
  width: 100%;
}

.company-image {
  width: 150px !important;
  height: 75px;
}

.collapsible {
  box-shadow: none !important;
  border: none !important;
}
.collapsible-header {
  border: none !important;
}

.expandible {
  justify-content: space-between;
}

.collection-item {
  font-size: 16px;
  border-bottom: none !important;
}
@media only screen and (min-width: 1024px) {
  .list {
    padding-left: 92px;
  }
}
@media only screen and (max-width: 600px) {
  .list {
    display: inline-block;
    width: 300px;
  }
}
